// en.json
export const English = {
  hello: 'Hello!',
  'Good morning': 'Good morning!',
  'Good afternoon': 'Good afternoon!',
  'Good evening': 'Good evening!',
  Featured: 'Featured',
  Currency: 'USD',
  'Edit Profile': 'Edit Profile',
  Username: 'Username',
  Birthday: 'Birthday',
  Language: 'Language',
  'Select language': 'Select language',
  'Select birthday': 'Select birthday',
  'Changes saved': 'Cambios guardados',
  'Something went wrong': 'Something went wrong',
  'Save Changes': 'Save Changes',
  Home: 'Home',
  Content: 'Content',
  Library: 'Library',
  Back: 'Back',
  Search: 'Search',
  Favorites: 'Favorites',
  Settings: 'Settings',
  Loading: 'Loading',
  Account: 'Your Account',
  'Your Account': 'Your Account',
  Profile: 'Profile',
  Logout: 'Logout',
  'Yes, Logout': 'Yes, Logout',
  'Are you sure you want to logout?': 'Are you sure you want to logout?',
  Cancel: 'Cancel',
  'Start the day with some morning stretches':
    'Start the day with some morning stretches',
  'Shake out the day with some fun movement.':
    'Shake out the day with some fun movement.',
  'Get ready for bedtime with these soothing practices.':
    'Get ready for bedtime with these soothing practices.',
  All: 'All',
  Move: 'Move',
  Meditate: 'Meditate',
  Learn: 'Learn',
  'Learn More': 'Learn More',
  Sleep: 'Sleep',
  Teachers: 'Teachers',
  Email: 'Email',
  Password: 'Password',
  "Let's Practice.": "Let's Practice.",
  'Your Favorites': 'Your Favorites',
  Signup: 'Signup',
  Login: 'Login',
  'What is your email?': 'What is your email?',
  'Create Account': 'Create Account',
  'Creating account...': 'Creating account...',
  'Error. Please try again.': 'Error. Please try again.',
  'Password must be at least 7 characters long.':
    'Password must be at least 7 characters long.',
  'Error creating profile. Try again.': 'Error creating profile. Try again.',
  'You are not old enough to join Wellemental.':
    'You are not old enough to join Wellemental.',
  'Your profile is missing required information. Please update it below.':
    'Your profile is missing required information. Please update it below.',
  'Practice with': 'Practica con',
  Submit: 'Submit',
  'Forgot password?': 'Forgot password?',
  'Reset Password': 'Reset Password',
  'Upgrade App': 'Upgrade App',
  'Tap to download the latest Wellemental update.':
    'Tap to download the latest Wellemental update',
  Download: 'Download',
  'App Upgrade Required!': 'App Upgrade Required!',
  'A critical update is required. Tap below to download the latest Wellemental update.':
    'A critical update is required. Tap below to download the latest Wellemental update.',
  'No Thanks': 'No Thanks',
  Required: 'Required',
  Plans: 'Plans',
  Plan: 'Plan',
  Purchase: 'Purchase',
  Subscription: 'Subscription',
  'Promo Code': 'Promo Code',
  'Promo code': 'Promo code',
  'Promo code?': 'Promo code?',
  'Access code?': 'Access code?',
  'Access code': 'Access code',
  'Access Code': 'Access Code',
  New: 'New',
  'No results': 'No results',
  'New account?': 'New account?',
  Subscribe: 'Subscribe',
  'Get unlimited access to our content with an annual or monthly subscription.':
    'Get unlimited access to our content with an annual or monthly subscription.',
  'Or enter your access code': 'Or enter your access code',
  '100+ meditation and yoga videos': 'Explore 100+ mindful practices',
  'Explore 100+ mindful practices from diverse teachers in english and spanish!':
    'Explore 100+ mindful practices from diverse teachers in English and Spanish!',
  'Available in English and Spanish': 'Practice in English and Spanish',
  'Led by diverse teachers': 'Learn from diverse teachers',
  'Online or offline use': 'Breathe and move – online or offline',
  'Save for offline use': 'Save for offline use',
  'Save for offline': 'Save for offline',
  mo: 'mo',
  yr: 'yr',
  'Subscribe for $6.99 / mo': 'Subscribe for $6.99 / mo',
  'Subscribe Annually': 'Subscribe Annually',
  'Subscribe Monthly': 'Subscribe Monthly',
  'Subscribe for $55 / yr': 'Subscribe for $55 / yr',
  'Tap the heart icon to favorite content':
    'Tap the heart icon to favorite content',
  'Save your favorite videos': 'Save your favorite videos',
  Monthly: 'Monthly',
  Annual: 'Annual',
  'Content refreshed': 'Content refreshed',
  'One moment...': 'One moment...',
  'Ask your parents': 'Ask your parents!',
  'What is 9 x 11?': 'What is 9 x 11?',
  'Please ask your parents to proceed': 'Please ask your parents to proceed.',
  Answer: 'Answer',
  'You did it!': 'You did it!',
  'Keep going': 'Keep going',
  'Explore by age range': 'Explore by age range',
  Explore: 'Explore',
  'Under 4 years': 'Under 4 years',
  'Simple practices for early learners': 'Simple practices for early learners',
  '4–9 year olds': '4–9 year olds',
  'Elementary mindful practices': 'Elementary mindful practices',
  '10–14 year olds': '10–14 year olds',
  'Practices designed for middle-school learning':
    'Practices designed for middle-school learning',
  '14–18 year olds': '14–18 year olds',
  'Resources for mindful development': 'Resources for mindful development',
  'Recurring billing. Cancel anytime for any reason.':
    'Recurring billing. Cancel anytime for any reason.',
  'By joining Wellemental you agree to our ':
    'By joining Wellemental you agree to our ',
  'terms of use': 'terms of use',
  'privacy policy': 'privacy policy',
  and: 'and',
  'An inclusive space for kids to breathe.':
    'An inclusive space for kids to breathe.',
  'Spark a mindful practice with the children in your life. Learn meditation and yoga with Wellemental.':
    'Spark a mindful practice with the children in your life. Learn meditation and yoga with Wellemental.',
  'Check your email for a reset password link.':
    'Check your email for a reset password link.',
  'See all languages': 'See all languages',
  'Enter email...': 'Enter email...',
  'Live Event': 'Live Event',
  Stats: 'Stats',
  Journey: 'Journey',
  'Load More': 'Load More',
  'Total Hours': 'Total Hours',
  Streak: 'Streak',
  'Sessions Completed': 'Sessions Completed',
  'Rate App': 'Rate App',
  History: 'History',
  'Notifications are already enabled!': 'Notifications are already enabled!',
  'Receive push notifications about app updates and new content?':
    'Receive push notifications about app updates and new content?',
  'Enable Notifications': 'Enable Notifications',
  'To enable push notifications, go to your iOS settings and find Wellemental within the Notifications list.':
    'To enable push notifications, go to your iOS settings and find Wellemental within the Notifications list.',
  Notifications: 'Notifications',
  Help: 'Help',
  'Pay Now': 'Pay Now',
  'There was an error creating your subscription. Please try again. If problem persists, email hello@wellemental.co.':
    'There was an error creating your subscription. Please try again. If problem persists, email hello@wellemental.co.',
  'Submit Payment': 'Submit Payment',
  'To manage your subscription, please click the button below.':
    'To manage your subscription, please click the button below.',
  "You don't have an active Wellemental subscription. Subscribe now!":
    "You don't have an active Wellemental subscription. Subscribe now!",
  "You don't have an active Wellemental subscription. Subscribe below! If you have any issues, email us at hello@wellemental.co":
    "You don't have an active Wellemental subscription. Subscribe below! If you have any issues, email us at hello@wellemental.co",
  'Payment Type': 'Payment Type',
  Website: 'Website',
  'To manage your subscription, you need to use your Apple iOS device and follow the instructions below.':
    'To manage your subscription, you need to use your Apple iOS device and follow the instructions below.',
  'Need help?': 'Need help?',
  'Manage Subscription': 'Manage Subscription',
  ' to learn how to cancel your subscription on.':
    ' to learn how to cancel your subscription on.',
  Status: 'Status',
  'You subscribed for free using an friends and family access code. Enjoy!':
    'You subscribed for free using an friends and family access code. Enjoy!',
  'To manage your subscription, login to the Wellemental website and navigate to your subscription page.':
    'To manage your subscription, login to the Wellemental website and navigate to your subscription page.',
  'Subscription type unknown. Please contact us at hello@wellemental.co with any questions.':
    'Subscription type unknown. Please contact us at hello@wellemental.co with any questions.',
  days: 'days',
  'Total Time': 'Total Time',
  'Your recently played videos will appear here. Get started!':
    'Your recently played videos will appear here. Get started!',
  'Your favorite videos will appear here. Get started!':
    'Your favorite videos will appear here. Get started!',
  'If payment succeeded, please wait one minute for your account to be upgraded.':
    'If payment succeeded, please wait one minute for your account to be upgraded.',
  years: 'years',
  'See all': 'See all',
  'Error loading content': 'Error loading content',
  'Friends & Family': 'Friends & Family',
  'Click below to get started.': 'Click below to get started.',
  'Download iOS': 'Download iOS',
  'Download Android': 'Download Android',
  'Go To Homepage': 'Go To Homepage',
  'Contact Us': 'Contact Us',
  'Back to Login': 'Back to Login',
  'Open the Settings app.': 'Open the Settings app.',
  'Tap your name.': 'Tap your name.',
  'Tap Subscriptions.': 'Tap Subscriptions.',
  'Tap the subscription that you want to manage.':
    'Tap the subscription that you want to manage.',
  'Tap Cancel Subscription.': 'Tap Cancel Subscription.',
  'View Plans': 'View Plans',
  Checkout: 'Checkout',
  '100% Satisfaction Guarantee. Cancel anytime.':
    '100% Satisfaction Guarantee. Cancel anytime.',
  Payment: 'Payment',
  'Enter payment info': 'Enter payment info',
  'Monthly Payment': 'Monthly Payment',
  'Annual Payment': 'Annual Payment',
  'Free Trial Length': 'Free Trial Length',
  'Amount Paid Now': 'Amount Paid Now',
  'Enter promo code': 'Enter promo code',
  'Invalid promo code': 'Invalid promo code',
  'Questions?': 'Questions?',
  "You're In!": "You're In!",
  'Please login or signup before entering your access code.':
    'Please login or signup before entering your access code.',
  'Enter Access Code': 'Enter Access Code',
  'applied. 30 day free trial added.': 'applied. 30 day free trial added.',
  'To cancel your Android subscription': 'To cancel your Android subscription',
  'Click here to learn how to cancel your subscription on Android.':
    'Click here to learn how to cancel your subscription on Android.',
  ' to learn how to cancel your subscription on Android.':
    ' to learn how to cancel your subscription on Android.',
  'Click here': 'Click here',
  'Start Morning Meditation': 'Start Morning Meditation',
  'Start Afternoon Meditation': 'Start Afternoon Meditation',
  'Start Evening Meditation': 'Start Sleep Meditation',
  Categories: 'Categories',
  Energize: 'Energize',
  'Anxiety & Stress': 'Anxiety & Stress',
  Focus: 'Focus',
  Rest: 'Rest',
  'Yoga Philosophy': 'Yoga Philosophy',
  'History of Yoga': 'History of Yoga',
  'Yoga Breaks': 'Yoga Breaks',
  'Full-Length Yoga Classes': 'Full-Length Yoga Classes',
  'Account upgraded! Close this page and wait a few seconds for the app to update.':
    'Account upgraded! Close this page and wait a few seconds for the app to update.',
  "Don't ask again": "Don't ask again",
  'Promo code applied. 30 day free trial added.':
    'Promo code applied. 30 day free trial added.',
};
