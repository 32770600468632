// es.json
export const Español = {
  hello: 'Hola!',
  'Good morning': '¡Buenos dias!',
  'Good afternoon': '¡Buenas tardes!',
  'Good evening': '¡Buenas noches!',
  Featured: 'Destacado',
  Currency: 'USD',
  'Edit Profile': 'Editar Perfíl',
  Username: 'Nombre de Usuario',
  Birthday: 'Cumpleaños',
  Language: 'Idioma',
  'Select language': 'Elige el idioma',
  'Select birthday': 'Selecciona cumpleaños',
  'Changes saved': 'Changes saved',
  'Something went wrong': 'Algo salió mal',
  'Save Changes': 'Guardar cambios',
  Home: 'Inicio',
  Content: 'Contenido',
  Library: 'Biblioteca',
  Back: 'Regresar',
  Search: 'Buscar',
  Favorites: 'Favoritos',
  Settings: 'Configuración',
  Loading: 'Descargando',
  Account: 'Tu Cuenta',
  'Your Account': 'Tu Cuenta',
  Profile: 'Perfíl',
  Logout: 'Cerrar Sesión',
  'Yes, Logout': 'Si, cerrar sesión',
  'Are you sure you want to logout?':
    '¿Está seguro que quiere cerrar la sesión?',
  Cancel: 'Cancelar',
  'Start the day with some morning stretches':
    'Comienza el día con estiramientos matutinos',
  'Shake out the day with some fun movement.':
    'Sacude el día con movimientos divertidos.',
  'Get ready for bedtime with these soothing practices.':
    'Prepárate para dormir con estas practicas calmantes',
  All: 'Todo',
  Move: 'Muévete',
  Meditate: 'Medita',
  Learn: 'Aprende',
  'Learn More': 'Aprende Más',
  Sleep: 'Duerme',
  Teachers: 'Maestros',
  Email: 'Correo Electrónico',
  Password: 'Contraseña',
  "Let's Practice.": 'Practiquemos',
  'Your Favorites': 'Tus favoritos',
  Signup: 'Registrate',
  Login: 'Iniciar Sesión',
  'What is your email?': '¿Cuál es tu correo electrónico?',
  'Create Account': 'Crea Tu Cuenta',
  'Creating account...': 'Creando tu cuenta...',
  'Error. Please try again.': 'Error. Por favor intentar otra vez.',
  'Password must be at least 7 characters long.':
    'La contraseña tiene que tener por lo menos 7 caracteres.',
  'Error creating profile. Try again.':
    'Hubo un error creando el perfil. Inténtalo otra vez.',
  'You are not old enough to join Wellemental.':
    'No tienes la edad suficiente para ser parte de Wellemental.',
  'Your profile is missing required information. Please update it below.':
    'A tu perfil le falta informacion requerida. Hacia abajo porfavor pone tu perfil al dia.',
  'Practice with': 'Practica con',
  Submit: 'Entregar',
  'Forgot password?': '¿Se te olvidó tu contraseña?',
  'Reset Password': 'Restablecer la contraseña',
  'Upgrade App': 'Actualizar aplicación',
  Download: 'Descargar',
  'App Upgrade Required!': '¡Se requiere actualización de la aplicación!',
  'Tap below to download the latest Wellemental update.':
    'Toque a continuación para descargar la última actualización de Wellemental.',
  'Tap to download the latest Wellemental update.':
    'Toque para descargar la última actualización de Wellemental.',
  'A critical update is required. Tap below to download the latest Wellemental update.':
    'Se requiere una actualización crítica. Toque a continuación para descargar la última actualización de Wellemental.',
  'No Thanks': 'No, gracias',
  'Contact Us': 'Contactarnos',
  Required: 'Requerida',
  Plans: 'Planes',
  Plan: 'Plan',
  Purchase: 'Compra',
  Subscription: 'Subscripcion',
  'Promo code': 'Código promocional',
  'Promo Code': 'Código promocional',
  'Promo code?': '¿Código promocional?',
  'Access code?': '¿Código de accesso?',
  'Access code': 'Código de accesso',
  'Access Code': 'Código de accesso',
  'No results': 'No hay resultados',
  'New account?': '¿Nueva cuenta?',
  New: 'Nuevo',
  Subscribe: 'Subscribe',
  'Get unlimited access to our content with an annual or monthly subscription.':
    'Recibe accesso ilimitado a nuestro contenido con una subscripcion annual o mensual.',
  'Or enter your access code': 'O entra tu código de accesso',
  '100+ meditation and yoga videos': '100+ videos de meditación y yoga',
  'Explore 100+ mindful practices from diverse teachers in english and spanish!':
    '¡Explore más de 100 prácticas conscientes con diversos profesores en inglés y español!',
  '100+ meditation and yoga videos and more':
    '100+ videos de meditación, yoga y más!',
  'Available in English and Spanish': 'Disponible en Ingles y Español',
  'Led by diverse teachers': 'Dirigido por maestros diversos',
  'Online or offline use': 'En línea o fuera de línea',
  'Save for offline use': 'Guarda para fuera de línea',
  'Save for offline': 'Guarda para fuera de línea',
  mo: 'mes.',
  yr: 'anu.',
  'Subscribe Annually': 'Suscríbete anualmente',
  'Subscribe Monthly': 'Suscríbete mensualmente',
  'Subscribe for $6.99 / mo': 'Suscribete por $6.99 / mes',
  'Subscribe for $55 / yr': 'Suscribete por $55 / anu',
  'Tap the heart icon to favorite content':
    'Toca el icono de corazon para seleccionar contenido favorito ',
  'Save your favorite videos': 'Guarda tus videos favoritos',
  Monthly: 'Mensual',
  Annual: 'Anual',
  'Content refreshed': 'Contenido actualizado',
  'One moment...': 'Un momento...',
  'Ask your parents': '¡Pregunta a tus padres!',
  'What is 9 x 11?': 'Lo que es 9 x 11?',
  'Please ask your parents to proceed': 'Pide a tus padres que continúen.',
  Answer: 'Tu respuesta',
  'You did it!': '¡Lo hiciste!',
  'Keep going': 'Sigue adelante',
  'Explore by age range': 'Explorar por rango de edad',
  Explore: 'Explorar',
  'Under 4 years': 'Menores de 4 años',
  'Simple practices for early learners':
    'Prácticas sencillas para principiantes',
  '4–9 year olds': '4-9 años',
  'Elementary mindful practices': 'Consciente elemental',
  '10–14 year olds': '10-14 años',
  'Practices designed for middle-school learning':
    'Prácticas de la escuela secundaria',
  '14–18 year olds': '14-18 años',
  'Resources for mindful development':
    'Recursos para el crecimiento consciente',
  'Recurring billing. Cancel anytime for any reason.':
    'La facturación es recurrente. Cancelar en cualquier momento.',
  'By joining Wellemental you agree to our ': 'Al unirse Wellemental, acepta ',
  'terms of use': 'los términos y condiciones',
  'privacy policy': 'la política de privacidad',
  and: 'y',
  'An inclusive space for kids to breathe.': 'Subscribe',
  'Spark a mindful practice with the children in your life. Learn meditation and yoga with Wellemental.':
    'Recibe accesso ilimitado a nuestro contenido con una subscripcion annual o mensual.',
  'Check your email for a reset password link.':
    'Revise su correo electrónico para obtener un enlace para restablecer la contraseña.',
  'See all languages': 'Ver todos los idiomas',
  'Enter email...': 'Ingrese el correo electrónico...',
  'Please login or signup before entering your access code.':
    'Por favor inicie sesión o regístrese antes de introducir su código de acceso.',
  'Enter Access Code': 'Ingrese el código de acceso',
  'Live Event': 'Evento en vivo',
  Stats: 'Estadísticos',
  Journey: 'Historia',
  'Load More': 'Cargar más',
  'Load more': 'Cargar más',
  'Total Hours': 'Horas',
  Streak: 'Días consecutivos',
  'Sessions Completed': 'Sesiones completadas',
  'Rate App': 'Evaluar App',
  History: 'Historia',
  'Notifications are already enabled!':
    '¡Las notificaciones ya están activadas!',
  'Receive push notifications about app updates and new content?':
    '¿Recibir notificaciones de empuje sobre actualizaciones de app y nuevo contenido?',
  'Enable Notifications': 'Activar notificaciones',
  'To enable push notifications, go to your iOS settings and find Wellemental within the Notifications list.':
    'Para permitir notificaciones de empuje, ve a la configuración de iOS y busca Wellemental en la lista de notificaciones.',
  Notifications: 'Notificacións',
  Help: 'Centro de Ayuda',
  'There was an error creating your subscription. Please try again. If problem persists, email hello@wellemental.co.':
    'Se ha producido un error al crear la suscripción. Por favor, inténtelo de nuevo. Si el problema persiste, envíe un correo electrónico hello@wellemental.co.',
  'Submit Payment': 'Enviar Pago',
  'To manage your subscription, please click the button below.':
    'Para administrar su suscripción, haga clic en el botón de abajo.',
  "You don't have an active Wellemental subscription. Subscribe now!":
    'No tiene una suscripción de Wellemental activa. ¡Suscríbase ahora!',
  "You don't have an active Wellemental subscription. Subscribe below! If you have any issues, email us at hello@wellemental.co":
    'No tiene una suscripción de Wellemental activa. ¡Suscríbase abajo! Si tiene algún problema, envíenos un correo electrónico a hello@wellemental.co',
  'Payment Type': 'Tipo de Pago',
  Website: 'Sitio Web',
  'To manage your subscription, you need to use your Apple iOS device and follow the instructions below.':
    'Para administrar su suscripción, debe utilizar su dispositivo Apple iOS y seguir las instrucciones que aparece abajo',
  'Need help?': '¿Necesita ayuda?',
  'Manage Subscription': 'Administrar suscripción',
  ' to learn how to cancel your subscription on Android.':
    ' para saber cómo cancelar su suscripción en Android.',
  Status: 'Estatus',
  'You subscribed for free using an friends and family access code. Enjoy!':
    'Te subscribiste con un código de acceso para amigos y familiares ¡Disfruta!',
  'To manage your subscription, login to the Wellemental website and navigate to your subscription page.':
    'Para administrar su suscripción, inicie sesión en el sitio web de Wellemental y navegue hasta su página de suscripción.',
  'Subscription type unknown. Please contact us at hello@wellemental.co with any questions.':
    'Tipo de suscripción desconocido. Por favor contáctenos en hello@wellemental.co con cualquier pregunta.',
  days: 'dias',
  'Total Time': 'Tiempo Total',
  'Your recently played videos will appear here. Get started!':
    'Tus vídeos reproducidos recientemente aparecerán aquí. ¡Empiece!',
  'Your favorite videos will appear here. Get started!':
    'Tus videos favoritos aparecerán aquí. ¡Empezar!',
  'If payment succeeded, please wait one minute for your account to be upgraded.':
    'Si el pago se realizó correctamente, espere un minuto para que se actualice su cuenta.',
  years: 'años',
  'Error loading content': 'Error al cargar contenido',
  'Friends & Family': 'Amigos y Familia',
  'Click below to get started.': 'Haga clic abajo para comenzar.',
  'Download iOS': 'Descargar iOS',
  'Download Android': 'Descargar Android',
  'Go To Homepage': 'Ir a la página de inicio',
  'Back to Login': 'Volver',
  'Open the Settings app.': 'Abre la app Configuración.',
  'Tap your name.': 'Toca tu nombre.',
  'Tap Subscriptions.': 'Toca Suscripciones.',
  'Tap the subscription that you want to manage.':
    'Toca la suscripción que quieras administrar.',
  'Tap Cancel Subscription.': 'Toca Cancelar suscripción.',
  'View Plans': 'Ver planes',
  Checkout: 'Revisa',
  '100% Satisfaction Guarantee. Cancel anytime.':
    '100% Garantía de Satisfacción. Cancelar en cualquier momento.',
  Payment: 'Abono',
  'Pay Now': 'Pagar Ahora',
  'Monthly Payment': 'Pago Mensual',
  'Annual Payment': 'Pago Anual',
  'Enter payment info': 'Entra la información de pago',
  'Free Trial Length': 'Duracion de prueba gratuita',
  'Amount Paid Now': 'Cantidad pagada ahora',
  'Enter promo code': 'Ingrese código promocional',
  'Invalid promo code': 'Código de promoción no válido',
  'Questions?': '¿Preguntas?',
  "You're In!": '¡Bienvenidos!',
  'applied. 30 day free trial added.':
    'aplicado. 30 días de prueba gratuita agregada.',
  ' to learn how to cancel your subscription on':
    ' para saber cómo cancelar su suscripción en',
  'To cancel your Android subscription':
    'Para cancelar su suscripción de Android',
  'Click here': 'Haga clic aquî',
  'See all': 'Ver todos',
  'Start Morning Meditation': 'Iniciar la meditación',
  'Start Afternoon Meditation': 'Iniciar la meditación',
  'Start Evening Meditation': 'Iniciar la meditación',
  Categories: 'Categorias',
  Energize: 'Energizante',
  'Anxiety & Stress': 'Ansiedad',
  Focus: 'Concentrarse',
  Rest: 'Calmese',
  'Yoga Philosophy': 'Filosofía de Yoga',
  'History of Yoga': 'Historia de Yoga',
  'Yoga Breaks': 'Descanso de Yoga',
  'Full-Length Yoga Classes': 'Clase Entera de Yoga ',
  'Account upgraded! Close this page and wait a few seconds for the app to update.':
    '¡Cuenta actualizada! Cierre esta página y espere unos segundos a que se actualice la aplicación. ',
  "Don't ask again": 'No volver a preguntar',
  'Promo code applied. 30 day free trial added.':
    'Código de promoción aplicado. 30 días de prueba gratuita agregada.',
};
